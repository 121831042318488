<template lang="pug">
    div(class="container m-auto my-3" :class="{'p-0 m-0': isExternal}")
        FormHeader(
            v-if="!isLoading && isExternal && showHeader"
            :title="title"
            :form-due-date="dueDate"
            :percent-done="percentDone"
            :form-answer-id="formAnswerId"
            :tenant-id="tenantId"
            :status="status"
            :auth0-id="auth0Id"
            :scores="scoreData"
            @status-update="updateFormAnswerStatusHandler"
        )
        Form(
            :form-props="formPropsFromRoute"
            :form-title.sync="title"
            :read-only="readOnly"
            @update-form-stats="updateStats"
            @update-form-answer-status="updateFormAnswerStatusHandler"
        )
</template>

<script>
import {onMounted, reactive, ref} from '@vue/composition-api'
import Form from '@/components/forms/Form'
import FormHeader from '@/components/forms/partials/FormHeader'
import camelcaseKeys from 'camelcase-keys'
import { logErrorMessages } from '@vue/apollo-util'
import {percentage} from '@/components/shared/modules/forms'
import {useLazyQuery} from '@vue/apollo-composable'
import {GET_FORM_ANSWER_STATS} from '@/graphql/queries/formAnsweFunctions.gql'
import {createNamespacedHelpers} from 'vuex-composition-helpers'

const { useActions } = createNamespacedHelpers('')

const onErrorCallBack = (error) => {
    console.log('error that happened')
    logErrorMessages(error)
}

export default {
    name: 'DynamicFormView',
    components: {Form, FormHeader},
    setup(_, context) {
        const { setThankYouRedirectQueryParams } = useActions(['setThankYouRedirectQueryParams'])
        const route = context?.root.$route
        const params = route?.params
        const query = route?.query
        let refetchQuery = reactive({})
        const title = ref('Patient Form')
        const percentDone = ref(0)
        const formAnswerId = ref('')
        const tenantId = ref('')
        const isExternal = ref(false)
        let isLoading = ref(true)
        let errorText = ref('')
        const dueDate = ref('')
        const showHeader = ref(true)
        const formPropsFromRoute = ref(params)
        const status = ref('')
        const readOnly = ref(!!query.ro)
        const formSetId = ref(query.fsi)
        const practiceName = ref(query.practiceName)
        const auth0Id = ref(query?.uid)
        const { setReadOnly, setIsExternal } = useActions(['setReadOnly', 'setIsExternal'])
        const scoreData = ref({})

        setReadOnly(readOnly.value)

        formAnswerId.value = params?.formAnswerId || query?.fai
        tenantId.value = query?.tenant
        isExternal.value = !!query?.ext
        showHeader.value = !query?.nh
        setIsExternal(isExternal.value)

        const updateFormAnswerStatusHandler = (val) => {
            status.value = val
        }

        const onResultCallBack = ({ data }) => {
            const stats = data?.formAnswerStats?.stats
            if (!stats) return

            const keys = Object.keys(stats)
            const key = keys[0]
            const stat = camelcaseKeys(stats[key], {deep: true})
            percentDone.value = percentage(stat?.completedQuestions, stat?.maxQuestions)
            dueDate.value = stat?.dueDate || ''
            scoreData.value = stat?.score || {}
        }

        const { onResult, loading, error, refetch, load, onError } = useLazyQuery(GET_FORM_ANSWER_STATS, {
            formAnswerIds: [formAnswerId.value],
            tenantId: tenantId.value
        })

        onResult(onResultCallBack)
        onError(onErrorCallBack)
        isLoading.value = loading.value
        refetchQuery = refetch
        errorText = error
        const updateStats = () => {
            refetchQuery()
        }

        if (formAnswerId.value) load()
        else isLoading.value = false

        // life cycle hooks
        onMounted(() => {
            setThankYouRedirectQueryParams({
                fsi: formSetId.value,
                tenant: tenantId.value,
                practiceName: practiceName.value
            })
        })

        return {
            title,
            formPropsFromRoute,
            isExternal,
            percentDone,
            refetchQuery,
            isLoading,
            errorText,
            dueDate,
            formAnswerId,
            tenantId,
            status,
            showHeader,
            readOnly,
            auth0Id,
            updateStats,
            updateFormAnswerStatusHandler,
            scoreData
        }
    }
}
</script>
