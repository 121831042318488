<template lang="pug">
    .bg-white-pure.l-box-shadow.rounded.px-4.py-3.mb-5.relative
        .w-full.flex.items-center.justify-between
            div(class="flex items-center mr-8")
                TypeIcon(
                    class="w-12 hideSmallTablet mr-2"
                    :rowData="iconObj"
                    svgColour="text-primary-light"
                )
                h4.font-display.font-semibold.text-left.text-xl
                    | {{title}}
                <!-- subname  div below for progress only appears for recurring forms -->
                <!-- <div class="ml-2 font-bold text-xs text-grey-2 uppercase">PROGRESS 1</div>-->
            .flex.items-center.mr-8
                v-progress-linear(
                    class="rounded-full w-40"
                    v-model="percentDone"
                    color="primary"
                    height="12"
                )
                .ml-2.uppercase.text-xs.mr-1.text-grey-2.font-bold.inline
                    | {{percentDone}}%
            .flex.items-center
                span(:class="[isComplete ? 'mr-6 text-sm uppercase flex flex-row items-center justify-between' : 'mr-6 text-sm text-red uppercase flex flex-row items-center justify-between']")
                    fa-icon(icon="calendar-alt" class="mr-1")
                    | DUE {{getFormattedDate(formDueDate)}}
            .flex.items-center
                v-btn(
                    v-show="score"
                    depressed
                    class="font-semibold text-xs p-1 ml-3 v-btn--flat"
                    color="primary"
                    type="button"
                    @click="openModalShowScore"
                )
                    | SCORES
            .flex.items-center
                .ml-6.text-sm.font-bold.uppercase
                    FormActions(
                        v-show="!score"
                        :disabled="!isComplete"
                        :is-complete="isComplete"
                        :status="formStatus"
                        :loading="updateFormLoading"
                        @finish-form="finish"
                    )
        div(class="mt-2 text-xs" v-show="isComplete")
            | Scores have already been submitted & cannot be re-submitted for a new score. Please note editing the form will not change the previously submitted score.

        div(v-if="isSavingText" class="fixed text-center z-50 px-4 py-2 savingMsg top-0 flex items-center" :class="{'bg-light-blue-light text-light-blue': isSaving, 'bg-green-light text-green': !isSaving}")     
            fa-icon(v-if="isSaving" icon="spinner" spin class="w-6 fa-lg")
            SavingComplete(v-if="!isSaving" class="sub-icon inline-block w-5")
            .text-sm.inline-block.align-middle.ml-1
                | {{ isSavingText }}
</template>

<script>
import TypeIcon from '@/components/partials/TypeIcon'
import {computed, ref} from '@vue/composition-api'
import {getFormattedDate} from '@/components/shared/modules/forms'
import FormActions from '@/components/forms/partials/FormActions'
import {provideApolloClient, useMutation, useQuery} from '@vue/apollo-composable'
import {FINALIZE_FORM} from '@/graphql/mutations/finalizeForm.gql'
import {FormMixins} from '@/components/shared/mixins/formSave'
import snakecaseKeys from 'snakecase-keys'
import {client} from '@/vue-apollo'
import {GET_FORM_ANSWER, GET_FORM_SCORE, GET_FORM_SCORES} from '@/graphql/queries/formAnsweFunctions.gql'
import {UPDATE_FORM_STATUS} from '@/graphql/mutations/formFunctions.gql'
import camelcaseKeys from 'camelcase-keys'
import {FORM_STATUS} from '@/modules/constants'
import {createNamespacedHelpers} from 'vuex-composition-helpers'
import {onErrorCallBack} from '@/utils/apollo.helpers'
import SavingComplete from '@/assets/icons/form/subaccordion_icon_complete.svg'

const { useGetters, useActions } = createNamespacedHelpers('')

provideApolloClient(client)

const COMPLETE_STATUS_LIST = [FORM_STATUS.Complete, FORM_STATUS.Finalized]

export default {
    name: 'FormHeader',
    components: {TypeIcon, FormActions, SavingComplete},
    mixins: [FormMixins],
    props: {
        title: {
            type: String,
            default: ''
        },
        formDueDate: {
            type: String,
            default: ''
        },
        percentDone: {
            type: Number,
            default: 0
        },
        formAnswerId: {
            type: String,
            default: ''
        },
        tenantId: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: ''
        },
        auth0Id: {
            type: String,
            default: ''
        },
        scores: {
            type: Object,
            default: () => ({})
        }
    },
    setup(props, { emit }) {
        const title = ref(props.title)
        const tenantId = ref(props.tenantId)
        const formAnswerId = ref(props.formAnswerId)
        const formStatus = ref(props.status)
        const score = ref(0)
        const formScores = ref([])
        const show = ref(false)
        const auth0Id = ref(props?.auth0Id)
        const { isSaving } = useGetters(['isSaving'])
        const { setModalOpen } = useActions(['setModalOpen'])

        const { mutate: finalizeForm, error: finalizeFormError, loading, onDone: finalizeOnDone, onError: finalizeOnError } = useMutation(FINALIZE_FORM)
        const { mutate: updateForm, error: updateFormError, loading: updateFormLoading, onDone: updateFormOnDone, onError: updateFormOnError } = useMutation(UPDATE_FORM_STATUS)

        const formAnswerIdRef = ref(props.formAnswerId)
        const tenantIdRef = ref(props.tenantId)
        const dialog = ref(false)

        // getting the form-answer
        const {
            onResult: formAnswerOnResult,
            onError: formAnswerOnError
        } = useQuery(GET_FORM_ANSWER, {
            formAnswerId: formAnswerId.value,
            tenantId: tenantId.value
        })
        // getting the form-score
        const { onResult, error, refetch, onError } = useQuery(GET_FORM_SCORE, snakecaseKeys({
            formAnswerId: formAnswerId.value,
            tenantId: tenantId.value
        }))
        const {
            onResult: getFormScoresResult,
            // error: getFormScoresError,
            // refetch: getFormScoresRefetch,
            onError: getFormScoresOnError
        } = useQuery(GET_FORM_SCORES, {
            formAnswerId: formAnswerId.value,
            tenantId: tenantId.value
        })

        //#region functions
        const finish = () => {
            if (!formAnswerIdRef.value) return
            // need to emit to the parent that we want to show the loader
            //TODO: change the target origin to something more concrete so we aren't braodcasting to everything
            parent.postMessage('showLoader', '*')
            // this.$apollo.mutate(FINALISE_FORM)
            const values = {formAnswerId: formAnswerIdRef.value, status: FORM_STATUS.Complete}
            const variables = snakecaseKeys(values, {deep: true})
            updateForm({...variables})
            const scoringVariables = snakecaseKeys({
                formAnswerId: formAnswerIdRef.value,
                tenant_id: tenantIdRef.value,
                completedById: auth0Id.value || ''
            }, {deep: true})
            finalizeForm({...scoringVariables})
        }

        const onResultCallBack = ({ data }) => {
            //should be a list
            console.log('data', data)
            score.value = data?.formScore?.score || 0
        }

        const onGetFormScoresResultCallBack = ({ data }) => {
            //should be a list
            console.log('data', data)
            formScores.value = [...data?.formScores]
        }

        const formAnswerOnResultCallback = ({ data: { formAnswer } }) => {
            formAnswer = camelcaseKeys(formAnswer[0] || {}, {deep: true})
            formStatus.value = formAnswer.status || FORM_STATUS.Incomplete
        }

        const openModalShowScore = () => {
            console.log('openning the modal', formScores.value)
            setModalOpen({
                isVisible: true,
                data: {
                    keepOpen: true,
                    modalClass: 'x-large-height-modal',
                    modalContentClass: 'w-3/5',
                    scores: formScores.value
                },
                componentName: 'ModalShowScore'
            })
        }
        //#endregion

        //#region query callback hooks
        formAnswerOnResult(formAnswerOnResultCallback)
        formAnswerOnError(onErrorCallBack)

        onResult(onResultCallBack)
        onError(onErrorCallBack)

        getFormScoresResult(onGetFormScoresResultCallBack)
        getFormScoresOnError(onErrorCallBack)

        finalizeOnDone(() => {
            // we shouldn't be setting the updateFormStatus here as finalize doesn't have access to it
            // formStatus.value = data?.updateFormStatus?.status || ''
            // now need to hide the loader
            parent.postMessage('hideLoader', '*')
        })

        finalizeOnError(error => {
            console.log('something went wrong', error)
            // want to hide loader if there's an error
            parent.postMessage('hideLoader', '*')
        })

        updateFormOnDone(({ data }) => {
            formStatus.value = data?.updateFormStatus?.status || ''
            refetch()
            emit('status-update', formStatus.value)
        })

        updateFormOnError(error => {
            console.log('something went wrong', error)
            // TODO: show an error
        })
        //#endregion

        //#region computed properties
        const iconObj = computed(() => {
            return {iconName: title.value?.replace(' ', '_').toUpperCase()}
        })

        const isComplete = computed(() => {
            return props.percentDone === 100 || !!score.value || COMPLETE_STATUS_LIST.indexOf(formStatus.value) > -1
        })
        const isSavingText = computed(() => {
            if (isSaving.value === null) return ''
            return isSaving.value ? 'Saving, please wait...' : 'All changes saved'
        })
        //#endregion

        return {
            show,
            iconObj,
            isComplete,
            loading,
            error,
            refetch,
            formStatus,
            score,
            finalizeFormError,
            updateFormError,
            updateFormLoading,
            isSaving,
            isSavingText,
            dialog,
            getFormattedDate,
            finish,
            openModalShowScore
        }
    }
}
</script>
